<template>
  <div class="app-container">
    <div class="content">
      <div class="title">
          <img src="@/assets/company/title_edit.png" style="width: 100%">
      </div>
      <el-form ref="mainForm" :rules="rules" :model="tmpData" label-width="8rem" class="form1">
        <el-row>
          <el-col :span="7" :offset="3">
            <el-form-item label='公司名称' prop='name' label-position="left">
              <el-autocomplete v-model="tmpData.name" :fetch-suggestions="querySearchAsync" placeholder="请输入单位名称" @select="handleSelect" :maxlength="100"></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="0">
            <el-form-item label='公司电话' prop="phone">
              <el-input v-model='tmpData.phone' maxlength="18" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7" :offset="3">
            <el-form-item label='纳税人识别号' prop="taxId" required>
              <el-input v-model='tmpData.taxId' maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="0">
            <el-form-item label='开户行' prop='bankName'>
              <el-input v-model='tmpData.bankName' maxlength="50" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7" :offset="3">
            <el-form-item label='公司地址' prop='addressCode'>
              <el-cascader
                  size="large"
                  :options="addressOptions"
                  :disabled="false"
                  v-model="tmpData.addressCode"
                  :placeholder="'请选择'"
                  @change="addressChange">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="0">
            <el-form-item label='详细地址' prop="detailAddress">
              <el-input v-model="tmpData.detailAddress"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7" :offset="3">
            <el-form-item label='银行账户' prop="bankAccountNo">
              <el-input v-model="tmpData.bankAccountNo">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <span style="color:red;">* 若是开票企业,请将公司信息维护完整</span>
          </el-col>
        </el-row>

        <div class="button">
          <el-button type="primary" class="btn" @click="submitForm" :loading='isLoading'>确定</el-button>
          <el-button class="btn" @click="back">取消</el-button>
        </div>
      </el-form>
    </div>

<!--    <explain :visible='explainVis' :cancel='explainCancel'></explain>
    <account :visible='accountVis' :cancel='accountCancel'></account>-->

  </div>
</template>

<script>
  import { regionData } from 'element-china-area-data'
  import explain from './modules/dialog/explain'
  import account from './modules/dialog/account'
  export default {
    name: "addCompany",
    components: {
      explain,
      account,
    },
    data() {
      const checkPhone = (rule, value, callback) => {
        if(value) {
          var pass=false;
          const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
          const tel = /^0\d{2,3}-?\d{7,8}$/ // 座机
          if(mobile.test(value)){
            pass=true;
          }
          if(tel.test(value)){
            pass=true;
          }
          if (!pass) {
            return callback(
                new Error("请输入公司电话(座机格式'区号-座机号码')")
            )
          }
        }
        callback()
      }
      const checkTax = (rule, value, callback) => {
        if(value =='') {
          return callback(new Error("请输入纳税人识别号"))
        }else if(
          value.length == 15 ||
          value.length == 16 ||
          value.length == 17 ||
          value.length == 18 ||
          value.length == 19 ||
          value.length == 20){
          const pattern = /^[A-Z0-9]+$/
          if(!pattern.test(value)){
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"))
          }
          callback()
        }else{
          return callback(new Error("请检查纳税人识别号"))
        }
      }
      return {
        restaurants: [],
        addressOptions: regionData,
        timeout: null,
        tmpData: {
          name: '',
          taxId:'',	//统一社会信用代码	query	false
          phone: '',
          bankName: '',
          address:'',
          addressCode: '',
          detailAddress: '',
          bankAccountNo: ''
        },
        rules: {
          name: [
            { required: true, message: "请输入单位名称" },
            { min: 1, max: 50, message: "单位名称长度在 1 到 50 个字符", trigger: 'blur' },
            {pattern: "^[^ ]+$",message:'公司名称不能含空格'}
          ],
          taxId: [
            { required: true, message: "请输入纳税人识别号" },
            { validator: checkTax, trigger: 'blur' }
            /*{ required: true, message: "请输入纳税人识别号" },
            { min: 1, max: 25, message: "纳税人识别号长度在 1 到 25 个字符" },
            { pattern: "^[A-Z0-9]+$", message: '纳税人识别号只能输入数字或大写英文字母', trigger: 'blur' }*/
          ],
          phone: [{ validator: checkPhone, trigger: 'blur' }],
          bankAccountNo: [{ min: 1, max: 30, message: "银行账户长度在 1 到 30 个字符" }],
          detailAddress: [{ min: 1, max: 30, message: "详细地址长度在 1 到 30 个字符" }]

        },
        isLoading: false,  // 提交等待框
        explainVis: false,  // 模态窗
        accountVis: false,

      };
    },
    methods: {
      submitForm() {
        this.$refs.mainForm.validate((valid) => {
          if (valid) {
            this.isLoading = true;
            this.$store.dispatch("CompanyCheck", {'id':this.tmpData.id,'taxId':this.tmpData.taxId,'userId':this.getToken('userId')}).then(async res => {
              if(res.success && res.data !== '' && res.data !== undefined){
                this.$confirm(res.data, '提示', {
                  cancelButtonText: '取消',
                  confirmButtonText: '确定',
                  type: 'warning'
                }).then(async () => {
                  this.edit()
                }).catch(err=>{
                  this.isLoading = false;
                  // if(err !== "cancel")
                  //   einvAlert.error("提示",err)
                })
              }else{
                this.edit()
              }
            }).catch(err=>{
              this.isLoading = false;
             // einvAlert.error("提示",err)
            })
          }
        });
      },
      edit(){
        const formData = this.tmpData;
        if(formData.phone !== undefined){
          formData.phone = encodeURIComponent(formData.phone);
        }
        let data = { createUserId: this.getToken('userId'), ...formData };
        this.$store.dispatch("CompanyEdit", data).then(res => {
          if (res.success) {
            this.isLoading = false;
            einvAlert.success("提示",'编辑成功')
            this.$router.push('/install/company');
          } else {
            einvAlert.error("提示",res.msg)
            this.isLoading = false
          }
        }).catch(err => {
          this.isLoading = false
        //  einvAlert.error("提示",err)
        })
      },
      back() {
        this.$router.push('/install/company');
      },
/*      explainCancel() {
        this.explainVis = false;
      },
      accountCancel() {
        this.accountVis = false;
      },*/

      querySearchAsync(queryString, cb) {
        if (!queryString) {
          cb()
          return
        }
        this.$store.dispatch("GetTaxList", {
          keyword: queryString
        }).then(res => {
          if (res.success) {
            this.restaurants = res.data.map((item) => {  //梳理数组结构
              return {
                taxId: item.creditcode,
                value: item.name
              };
            });
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(results);
            }, 500 * Math.random());
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        //  einvAlert.error("提示",err)
        })
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
        };
      },
      handleSelect(item) {
        this.tmpData.taxId = item.taxId
      },
      //省市联动
      addressChange(arr) {
        this.tmpData.address = arr[0] +','+ arr[1] +','+ arr[2]
      },
      getCompany(){
        this.$store.dispatch("FindCompanyById", {'companyId':this.$route.query.id,'userId': this.getToken('userId')}).then(res => {
          if(res.success){
            this.tmpData =res.data
            this.tmpData.addressCode = res.data.address.split(',')
          }
        }).catch(err=>{

        })
      }
    },
    mounted() {
      this.getCompany()
    }
  };
</script>

<style lang='less' scoped>
  .content {
    padding: 0 20px;
    height: calc(100% - 2rem);
    margin: 0 auto;
    background: #fff;
    .title {
      border-bottom: 1px solid #dcdcdc;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      position: relative;
      line-height: 70px;
      margin: 0;
      .btn-item {
        position: absolute !important;
        right: 2%;
        top: 32%;
      }
    }
  }
  .form1 {
    padding: 26px;
    .date {
      display: flex;
      /deep/.el-input__inner {
        width: 100px;
      }
      .year,
      .month {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .line {
    text-align: center;
  }
  .span-box {
    cursor: pointer;
    i {
      margin-right: 8px;
      color: #ff9700;
    }
  }
  .el-form-item__label {
    text-align: left;
  }
  .filter-item {
    width: 100%;
  }
  .years-item {
    width: 150px;
  }

  .button {
    margin: 40px auto 0;
    text-align: center;
    border-top: 1px solid #dcdcdc;
    padding-top: 40px;
    .btn {
      width: 150px;
      height: 40px;
      margin-right: 12px;
    }
  }
  /deep/ .el-autocomplete {
    width: 100%;
  }
  /deep/ .el-input__inner {
    width: 260px !important;
    height: 40px !important;
  }
</style>
